import React from "react";
import styles from "./about.module.scss";
import { Zoom, Fade } from "react-reveal";
import { Link } from "react-scroll";

function About(props) {
  return (
        <Zoom duration={1500} delay={500}>
    <div className={styles.about} id="about">
      <div className="row">
          <div className="col-12 col-lg-6 col-md-12">
            <div className={styles.side_1}></div>
          </div>

          <div className="col-12 col-lg-6 col-md-12">
            <div className={styles.side_2}>
              <div className={styles.upper}>
                <p className={styles.para_1}>About Us</p>
              </div>
              <p className={styles.para_2}>
                EcoCycle is dedicated to promoting responsible production and
                consumption patterns in Nigeria. They strive to enhance the way
                goods are produced and consumed to have a positive impact on the
                economy, society, and the environment. EcoCycle implements
                criteria, policies, and regulatory frameworks to monitor and
                enhance responsible production and consumption. They work
                closely with government agencies, businesses, and individuals to
                raise awareness, build capacity, and promote sustainable
                practices, resource management, and waste reduction. Engaging
                stakeholders and fostering collaboration with diverse partners
                are integral to their mission. EcoCycle aims to create a
                sustainable ecosystem that benefits all stakeholders by driving
                positive change and contributing to Nigeria's sustainable
                development. Join them on their journey towards responsible
                production and consumption for a sustainable future.
              </p>
            </div>
          </div>
          <Link
          activeClass="active"
          to="about"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Go to About Section
        </Link>
      </div>
    </div>
        </Zoom>
  );
}

export default About;
