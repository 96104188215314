import { AiFillHome, AiOutlineMenuUnfold } from "react-icons/ai";

import { FcServices } from "react-icons/fc";
import { BsInfoCircle } from "react-icons/bs";
import { MdContactMail, MdPriceChange } from "react-icons/md";


export const navData=[
    {
        label: "Home",
        path: "#",
        icon: <AiFillHome size={23} />,
      },
      {
        label: "About Us",
        path: "#about",
        icon: <BsInfoCircle size={23} />,
      },
      {
        label: "Our Goal",
        path: "#goal",
        icon: <BsInfoCircle size={23} />,
      },
      {
        label: "What We Do",
        path: "#service",
        icon: <FcServices size={23} />,
      },
      {
        label: "Why Choose Us",
        path: "#choose",
        icon: <FcServices size={23} />,
      },
      {
        label: "Meet The Team",
        path: "#team",
        icon: <BsInfoCircle size={23} />,
      },
];

function Navbar() {
  const handleNavClick = (path) => {
    const section = document.querySelector(path);
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <nav>
      <ul>
        {navData.map((item, index) => (
          <li key={index} onClick={() => handleNavClick(item.path)}>
            {item.icon}
            <span>{item.label}</span>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navbar;