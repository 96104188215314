import React from "react";
import Banner from "./banner/banner";
import About from "./about/about";
import Goal from "./goal/goal";
import Doings from "./whatWeDo/doings";
import Team from "./team/team";
import styles from './index.module.scss'

function Home(props) {
  return (
    <div className={styles.index}>
      <Banner />
      <About />
      <Goal />
      <Doings />
      <Team />
    
    </div>
  );
}

export default Home;
