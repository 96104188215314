import React from "react";
import styles from "./doings.module.scss";
import { Zoom , Fade } from "react-reveal";
import { Link } from "react-scroll";

function Choose(props) {
  return (
    <div className={styles.choose} id="choose">
      <div className="row">
        <Zoom duration={2000} delay={700}>
          <div className="col-12 col-lg-6 col-md-6">
            <div className={styles.side_1}> </div>
          </div>
        </Zoom>

< Fade duration={2000} delay={700} right>

        <div className="col-12 col-lg-6 col-md-6">
          <div className={styles.side_3}>
            <p className={styles.choose_para_1}>Why Choose Us</p>
            <p className={styles.choose_para_2}>
              Professional & Diplomatic Approach to Climate Protection.
            </p>
            <p className={styles.choose_para_3}>
              Goals: <br /> To minimize illegal implementation in rural
              communities across Nigeria by creating awareness to uptake advocacy
              for efficient production{" "}
            </p>
          </div>
        </div>
</Fade>
      </div>
    </div>
  );
}

export default Choose;
