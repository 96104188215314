import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import AppBar from "./component/appBar/appBar";
import Footer from "./component/appBar/footer/footer";

function App() {
  return (
    <div className="">
      {/* <AppBar /> */}
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
