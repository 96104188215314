import React from "react";
import styles from "./doings.module.scss";
// import { Link } from "react-router-dom";
import Choose from "./choose";
import { Zoom, Fade } from "react-reveal";
import { Link } from "react-scroll";

function Doings(props) {

 let nbsp = "\u00A0"
 let space = new Array(33).join(nbsp);

  const cardData = [
    {
      title: "Reduction of waste generation",
      link: "#",
    },
    {
      title:`Reduction of food wastage ${space}` ,
      link: "#",
    },
    {
      title: "Community leaders sensitization ",
      link: "#",
    },
    {
      title: "Boot Camp for youth on recycling",
      link: "#",
    },
    {
      title: "Organize workshops for market women",
      link: "#",
    },
    {
      title: "Efficient resources management",
      link: "#",
    },
  ];
  return (
    <div className={styles.doings} id="service">
      <div className={styles.upper}>
        <Fade top duration={2000} delay={700}>
          <p className={styles.para_1}> What we Do </p>

          <p className={styles.para_2}>
            To minimize the illegal implementation of recycling in rural
            communities by creating awareness to uptake advocacy for efficient
            production and consumption and support them with training and
            protective equipment which will enhance good productivity and
            contribute to a safe and healthy environment.
          </p>
        </Fade>

        <Fade duration={2000} delay={700} bottom>
          <div className={styles.cards}>
            <div className="row">
              {cardData.map((item, i) => (
                <div className=" col-12 col-lg-4 col-md-6">
                  <div className={styles.card}>
                    <div className={styles.card_para_1}>{item.title}</div>

                    {/* <div className={styles.card_para_2}> */}
                      {/* <Link to={item.link} className={styles.card_link}>
                      {" "}
                      Learn More
                    </Link> */}
                    {/* </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Fade>
      </div>
      <Choose />
    </div>
  );
}

export default Doings;