import React from "react";
import styles from "./team.module.scss";
import TeamCard from "./teamCard";
import {Zoom, Fade} from 'react-reveal';
import { Link } from "react-scroll";

function Team(props) {
  return (
    <div>
      <Zoom duration={2000} delay={700}>

      <div className={styles.team} id="team">
        <p className={styles.para_1}>Meet the team</p>
        <p className={styles.para_2}>
          - Support community leaders by a way of educating them in order to
          influence policies and regulations that support the SDG we are
          promoting in the community
        </p>
      </div>
      </Zoom>

      < Fade duration={2000} delay={700} right>

      <div className="container">
        <TeamCard />
      </div>
      </Fade>
    </div>
  );
}

export default Team;
