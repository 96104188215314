import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./appBar.module.scss";
import { Link } from "react-router-dom";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import Offcanvas from "react-bootstrap/Offcanvas";
import { navData } from "./appTtems";
import logo from "../../assets/logo.png";
function AppBar(props) {
  // const [inView, setInView] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollY = window.scrollY;
  //     const scrollThreshold = 120; // Specify the scroll threshold in pixels

  //     if (scrollY >= scrollThreshold) {
  //       setInView(true);
  //     } else {
  //       setInView(false);
  //     }
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const linkStyle = {
  //   textDecoration: "none",
  // };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  console.log(show);

  return (
    <div className={styles.appBar}>
      {/* <div
        style={{ width: inView ? "100%" : "", position: inView ? "fixed" : "" }}
        className={inView ? `${styles.header} ${styles.active}` : styles.header}
      > */}

      <div className={styles.header}>
        <div className={styles.title}>
          <img src={logo} className={styles.logo} alt="" />
          <div> EcoCycle</div>
        </div>

        <nav className={`${styles.hideOnMobile} ${styles.navs}`}>
          {navData.map((item) => (
            <Link to={item.path} className={` ${styles.nav_items}`}>
              {item.label}
            </Link>
          ))}
        </nav>
        <div className={styles.hideOnMobile}>
          <Link to="/pricing" className={` ${styles.btn}`}>
            Join the Cause
          </Link>
        </div>

        <div className={`${styles.icon} ${styles.hideOnDesktop}`}>
          <HiOutlineMenuAlt1
            color={"white"}
            size={29}
            onClick={() => setShow(true)}
          />

          <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <div className={styles.title}>
                  <img src={logo} className={styles.logo} alt="" />
                  <div> EcoCycle</div>
                </div>
              </Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
              <div className={styles.resp_nav}>
                {navData.map((item) => (
                  <Link
                    to={item.path}
                    onClick={handleClose}
                    className={styles.resp_navs}
                  >
                    {" "}
                    <span className={styles.resp_navs_logo}>
                      {item.icon}
                    </span>{" "}
                    {item.label}
                  </Link>
                ))}
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </div>
  );
}

export default AppBar;
