import React from "react";
import styles from "./footer.module.scss";
import img from "../../../assets/logo.png";
import { Link } from "react-router-dom";
import { FcFlashAuto } from "react-icons/fc";
import { Zoom } from "react-reveal";
import {
  BsSnapchat,
  BsFacebook,
  BsTwitter,
  BsInstagram,
  BsWhatsapp,
} from "react-icons/bs";

function Footer(props) {
  const quickLink = [
    {
      title: "Quick Link ",
      sub_1: "About us",
      sub_2: "Blog",
      sub_3: "Join the course",
    },
    {
      title: "Our Service",
      sub_1: "Boot camp",
      sub_2: "Work Shop",
      sub_3: "Waste Management",
    },
  ];
  return (
      <div className={styles.footer}>
        <div>
          <div className="container ">
            <div className="row">
              <div className="col-12 col-lg-3 col-md-6">
                <div>
                  <div className={styles.title}>
                    <img src={img} alt="" />{" "}
                    <span className={styles.title_text}>EcoCycle</span>
                  </div>
                  <p className={styles.title_sub}>A Subsidiary of FriendnPal</p>
                </div>
              </div>

              {quickLink.map((item) => (
                <div className="col-12 col-lg-3 col-md-6 ">
                  <p className={styles.quick}> {item.title}</p>
                  <p>
                    {" "}
                    <Link className={styles.quick_sub} to="#">
                      {item.sub_1}
                    </Link>
                  </p>
                  <p>
                    {" "}
                    <Link className={styles.quick_sub} to="#">
                      {item.sub_2}
                    </Link>
                  </p>
                  <p>
                    {" "}
                    <Link className={styles.quick_sub} to="#">
                      {item.sub_3}
                    </Link>
                  </p>
                </div>
              ))}

              <div className="col-12 col-lg-3 col-md-6 ">
                <div>
                  <p className={styles.quick}> Contact Us</p>
                  <p className={styles.quick_sub}>
                    <p>For more enquiries:</p>
                    {/* <p>Info@ecoCycle.com</p> */}
                    <p>Support@ecoCycle.com.ng</p>
                    <p style={{ fontWeight: "600" }}>+234 905 557 0782</p>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.line}>
              <Link className={styles.link}>
                {" "}
                <BsFacebook size={24} className={styles.icon} />{" "}
              </Link>
              <Link className={styles.link}>
                {" "}
                <BsTwitter size={24} className={styles.icon} />{" "}
              </Link>
              <Link className={styles.link}>
                {" "}
                <BsInstagram size={24} className={styles.icon} />{" "}
              </Link>
              <Link className={styles.link}>
                {" "}
                <BsWhatsapp size={24} className={styles.icon} />{" "}
              </Link>
              <Link className={styles.link}>
                {" "}
                <BsSnapchat size={24} className={styles.icon} />{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>

  );
}

export default Footer;
