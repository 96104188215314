import React from "react";
import styles from "./goal.module.scss";
// import { Link } from "react-router-dom";
import { Zoom } from "react-reveal";
import { Link } from "react-scroll";

function Goal(props) {
  return (
    <div className={styles.goal} id="goal">
      <Zoom duration={2000} delay={500}>
        <div className={styles.para}>
          <p className={styles.para_1}>Our Goal</p>
          <p className={styles.para_2}>
            To minimize illegal implementation in rural communities, by creating
            awareness to uptake advocacy for efficient production and
            consumption to women traders, people living at coastal areas, and
            support them with training and personal protective equipment that
            will enhance good productivity and contribute to a safe planet.
          </p>
        </div>
      </Zoom>
    </div>
  );
}

export default Goal;
