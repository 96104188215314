import React from "react";
import styles from "./banner.module.scss";
import { Link } from "react-router-dom";
// import { BsThreeDots } from "react-icons/bs";
import AppBar from "../../../component/appBar/appBar";

function Banner(props) {
  return (
    <div className={styles.banner}>
      <AppBar />
      <div className={styles.para}>
        <p className={styles.para_1}>
          Promoting Responsible Production and Consumption
        </p>
        <p className={styles.para_2}>
          {" "}
          enhancing good productivity and efficient consumption to promote
          sustainable planet.{" "}
        </p>
      </div>
      <p className={styles.para_3}>
        <Link className={styles.btn}> Join the Cause </Link>
        {/* <span className={styles.dots}>
          {" "}
          <BsThreeDots size={40} />
        </span> */}
      </p>
    </div>
  );
}

export default Banner;
