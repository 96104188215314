import React from "react";
import styles from "./team.module.scss";
import worker1 from "../../../assets/worker1.png";
import worker2 from "../../../assets/worker2.png";
import worker3 from "../../../assets/worker3.png";
import worker4 from "../../../assets/worker4.png";
// import worker1 from "../../../assets/worker1.png";
import { Link } from "react-router-dom";
function TeamCard(props) {
  const workersData = [
    {
      name: "Adijat Olayemi Taofeek",
      post: "Project Leader",
      img: worker2,
      path: "#",
    },
    {
      name: "Esther Eruchie",
      post: "Project Coordinator",
      img: worker1,
      path: "#",
    },
    {
      name: "Adesina Kayode",
      post: "Tech Lead",
      img: worker3,
      path: "#",
    },
    {
      name: "Fajana Samuel",
      post: "Project Lead Assistant",
      img: worker4,
      path: "#",
    },
    // {
    //   name: "Seun Opeyemi",
    //   post: "Design Lead",
    //   img: worker4,
    //   path: "#",
    // },
  ];
  return (
    <div className="row">
      {workersData.map((item) => (
        <div className="col-12 col-lg-3 col-md-6">
          <div className={styles.card}>
            <div className={styles.img}>
              <img src={item.img} alt=" worker image" />
            </div>

            <div className={styles.para}>
              <p>{item.post}</p>
              <p> {item.name}</p>
            </div>

            {/* <Link to={item.path} className={styles.readMore_btn}>
              {" "}
              Read More{" "}
            </Link> */}
          </div>
        </div>
      ))}
    </div>
  );
}

export default TeamCard;
